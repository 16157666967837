.ProjectPopup{
    position:fixed;
    top:0;
    left:0;
    width: 100vw;
    height:100vh;
    background-color: rgba(170, 170, 170, 0.486);
    display: flex;
    justify-content: center;
    z-index:2;
}

.inner-ProjectPopup{
    margin-top: 80px;
    position:relative;
    padding: 30px;
    display: flex;
    flex-direction:column;
    padding-left: 50px;
    background-image:linear-gradient(rgb(255, 255, 255),rgb(242, 255, 255));
    opacity: 0.98;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 600px;
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: visible;
}

.Closebutton{
    width:100%;
    display: flex;
    justify-content:flex-end;
    
}
#close{
    background-color:rgb(255, 255, 255);
    border:none;
    border-radius: 25px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border: 0.5px solid gray;
    padding: 4px;
}
#close:hover{
    height: 23px;
    width: 23px;
    background-color:rgb(233, 227, 255);
    border: 0.5px solid rgb(115, 176, 255);
}
@media only screen and (max-width:500px){
    .inner-ProjectPopup{
        max-width:90vw;
        padding-left: 30px;
    }
}