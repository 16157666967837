.MobilePopup{
    position:fixed;
    top:0;
    left:0;
    width: 100vw;
    height:100vh;
    background-color: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    z-index: 2;
}

.inner-MobilePopup{
    margin-top: 50px;
    position:relative;
    padding: 30px;
    width:60vw;
    background-color: rgb(255, 255, 255);
    max-width: 600px;
    max-height: 35vh;
    
}


.close_icon{
    position: absolute;
    top: 20px;
    right:30px
}

#items{
    width: 100%;
    display: flex;
    justify-content: center
}

#items:hover{
    background-color: rgb(238, 238, 238)
}