@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,600&display=swap');

.home_container1{
    margin-left: 85px;
    margin-right: 75px;
    display: flex;
    height:calc(100vh - 60px);
    align-items: center;
    
    
}
.mobile_view_1{
  display:none;
}
.mobile_view_2{
  display:none;
}
.home_container2{
    background-color: rgb(239, 251, 255);
    display: flex;
    height:100vh;
    width:auto;
    
}
#imageofrocket{
  /* background-color:aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  height:500px;
  width: 500px
}
#imageofrocket img{
  height:350px;
  width: 350px
}
.Inner{
  display: flex;
  margin-left: 75px;
  margin-right: 75px;
  height:100vh;
  width:100vw;
  align-items: center;
}
#Inner_image{
  width:45vw;
  margin-right: 50px;
}

#Inner_content{
  width:calc(50vw - 130px);
}
#Inner_text1{
  font-family:Lora;
  font-size:200%
}

#Top_content{
    width: 50vw;
}


#text0 {
  font-family:Lora;
    font-size:300%;
    color:rgb(64, 26, 107);
  }
  #text0:after {
    content:"";
    animation: spin 12s infinite;
    font-size: 85%;
  }
  @keyframes spin {
    0% { content: " AI Application";opacity: 0.25; color:#0099FF}

    5% { content: " Web Application"; opacity: 1;color:#0011FF }
    22% { content: " Web Application"; opacity: 1;color:#0011FF }
    25% { content: " Web Application"; opacity: 0.5 }
    28% { content: " Web Application"; opacity: 0.25 }

    30% { content: " Mobile Application";opacity: 1;color:#FF8800}
    52% { content: " Mobile Application";opacity: 1;color:#FF8800 }
    55% { content: " Mobile Application";opacity: 0.5 }
    58% { content: " Mobile Application";opacity: 0.25 }

    60% { content: " IOT Application";opacity: 1;color:#00CC59 }
    72% { content: " IOT Application";opacity: 1;color:#00CC59 }
    75% { content: " IOT Application";opacity: 0.5 }
    78% { content: " IOT Application";opacity: 0.25 }

    80% { content: " AI Application";opacity: 1;color:#0099FF }
    92% { content: " AI Application";opacity: 1;color:#0099FF }
    95% { content: " AI Application";opacity: 0.5 }
    100% { content: " AI Application";opacity: 0.25}
  }

#text1 {
  font-family:Lora;
  font-size:125%;
  color:rgb(18, 18, 97);
  height: 80px;
}

#text1:after {
  content:"";
  animation: text1_spin 12s infinite;
  color: #FF8800;
}
  
  @keyframes text1_spin {
    0% {content: "We will help you to develop your idea."}
    65% { content: "We are here to develop your fantasy into more powerful application.";}
    100%{content: "We will also help you in Digital Marketing"}
  }
 
  #text10 {
    font-family:Lora;
    font-size:150%;
    color:rgb(18, 18, 97);
    
  }
  #text11 {
    font-family:Lora;
    font-size:150%;
    color:rgb(18, 18, 97);
    
  }
  
  /* #text10:after {
    content:" ";
    animation: text10_spin 12s infinite;
    font-size:90%
  } */
    
    @keyframes text10_spin {
      0% {content: "AI Application";color:#0099FF}
      33% { content: "Web Application";color:#0011FF}
      67% {content: "IOT Application";color:#FF8800}
      100%{content: "Mobile Application";color:#00CC59}
    }

.address{
  height:8vh;
  background-color:rgb(254, 253, 255);
  display:flex;
  /* padding-bottom: 20px; */
}
#GetEstimation{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  outline:none;
  width:170px;
  font-size:16px;
  height: 40px;
  border: none;
  background-color:#00CC59;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color:white;
  cursor:pointer
}
#GetEstimation:hover{
  background-color:#FF8800
}
.mail{
  display: flex;
  align-items: center;
  color:rgb(18, 18, 97);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-top: 10px;
  font-size:70%;
  margin-left: 70px;
  margin-right:70px;
  
}
.mail img{
  margin-right: 10px;
}  
footer{
  display: flex;
  background-color:rgb(254, 253, 255);
  height:4vh;
}

.copyright{
  padding-top:3px;
  display: flex;
  justify-content: flex-end;
  margin-left:70px;
  margin-right: 70px;
  font-size: 10px;
  width: 100%;
}

#Top_button{
  width:200px;
  height:40px;
  cursor: pointer;
  outline:none;
  border:none;
  background-color:rgb(41, 191, 236);
  border-top-left-radius:15px;
  border-bottom-right-radius:15px;
  color:white;
  font-size:medium;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
#Top_button:hover{
  background-color:tomato;
}
#Inner_button{
  cursor: pointer;
  outline:none;
  border:none;
  background-color:#0099FF;
  width:150px;
  height:35px;
  font-size:14px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color:white;
}
#Inner_button:hover{
  background-color:#0011FF
}

.address_nav{
  cursor: pointer;
  display: flex;
  justify-content:space-around;
  width: 50%;
  color:rgb(18, 18, 97);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-top: 15px;
  font-size:70%;
}

.bottom_logo{
  padding-top:18px;
  width: 250px;
  display: flex;
  justify-content:flex-end;
}

.line{
  margin-left: 25px;
  margin-right: 40px;
  width: calc(100vw - 80px);
  background-color: rgba(180, 180, 180, 0.932);
  height:1px
}



@media only screen and (max-width: 800px){
  #imageofrocket{
    width: 300px;
    height:300px;
  }
  #imageofrocket img{
    width: 280px;
    height:280px;
  }
  #text0{
    font-size:170%
  }
}
@media only screen and (max-width: 700px){
  #text1{
    height: 100px
  }
  #imageofrocket{
    width: 250px;
    height:250px;
  }
  #imageofrocket img{
    width: 250px;
    height:250px;
  }

  #text0{
    font-size: 150%;
  }
}


.popup-content{
  font-family: Lora;
}

#YourName{
  display: flex;
}

#YourName input{
  height: 25px;
  width:auto;
  border:none;
  border-bottom: 1.5px solid rgb(83, 83, 83);
  background-color:transparent;
  color:rgb(38, 35, 73);
}

#YourName input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 91, 209);
}

#inp1{
  display: flex;
  align-items: center;
  margin-left:20px;
}

#projectName{
  display: flex;
}
#projectName input{
  height: 25px;
  width:auto;
  border:none;
  border-bottom: 1.5px solid rgb(83, 83, 83);
  background-color:transparent;
  color:rgb(38, 35, 73);
  
}

#projectName input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 91, 209);
}

#inp2{
  display: flex;
  align-items: center;
  margin-left:20px
}

#companyName{
  display: flex;
}

#companyName input{
  height: 25px;
  width:auto;
  border:none;
  border-bottom: 1.5px solid rgb(83, 83, 83);
  background-color:transparent;
  color:rgb(38, 35, 73);
}

#companyName input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 91, 209);
}

#inp3{
  display: flex;
  align-items: center;
  margin-left:20px
}
#Contact{
  display: flex;
}
#Contact input{
  height: 25px;
  width: auto;
  border:none;
  border-bottom: 1.5px solid rgb(83, 83, 83);
  background-color:transparent;
  color:rgb(38, 35, 73);
}

#Contact input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 91, 209);
}

#inp4{
  display: flex;
  align-items: center;
  margin-left:20px
}

#EmailId{
  display: flex;
}
#EmailId input{
  height: 25px;
  width: auto;
  border:none;
  border-bottom: 1.5px solid rgb(83, 83, 83);
  background-color:transparent;
  color:rgb(38, 35, 73);
}

#EmailId input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 91, 209);
}

#inp5{
  display: flex;
  align-items: center;
  margin-left:20px
}

#projectIdea{
  display: flex;
  flex-direction: column
}
#projectIdea textarea{
  height: 50px;
  width:300px;
  background-color:transparent;
  color:rgb(38, 35, 73);
  /* border: */
}
#inp6{
  display: flex;
  align-items: center;
  margin-left:20px
}

#need{
  display: flex;
}

#first{
  width: 45vw;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}


#first-inner{
  display: flex;
  align-items: center;
}

.Submit_Button{
  display: flex;
  width:100%;
  padding-top: 20px;
  justify-content: center;
}
#Button_Attributes{
  width:80px;
  height:25px;
  background-color:rgb(0, 100, 250);
  border-radius: 6px;
  outline:none;
  border:none;
  color:rgb(215, 237, 255);
}
@media only screen and (max-width: 550px){
  .mobile_view_1{
    display: flex;
    
  }
  .body_first{
    margin-top:7;
    margin: 8%;
    background-color:white;
    display:flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  
  #text10{
    width:100%;
    text-align: center;
  }
  #text11{
    height:2px;
    width:100%;
    text-align: center;
  }
  .body1{
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
  .body1 #body1_h2{
    font-family:Lora;
    font-size:145%;
    color:rgb(64, 26, 107);
  }
  .mobile_view_2{
    display: flex;
    background-image: url(./bg_color.svg);
    background-position: flex-start;
    background-repeat: no-repeat;
  }
  .body_second{
    display: flex;
    margin: 7%;
    width:100%;
    align-items: center;
    flex-direction: column;
  }

  .firstbox{
    margin-top: 80px;
    width:90%;
    height: 250px;
    border-radius:20px;
    box-shadow: rgba(48, 48, 255, 0.418) 0px 50px 100px -20px, rgba(0, 0, 0, 0.562) 0px 30px 60px -30px;
    background-color:white;
    opacity:1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left:11px solid #FF8800;
  }
  .swiper_Div{
    background-color: white;
    border-radius: 20px;
    border-left: 11px solid tomato;
    width:80vw;
    min-height: 250px;
    height: 250px;
   
  }
  .swiper{
    width:80vw;
    height: 250px;
    border-radius: 20px;
    margin-top: 50px;
    background-color:white;
    
  }
  
  #checkbox_con{
    font-size: 12px;
    /* font-family:; */
  }
  .swippingContent{
    height:75%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
 
  }
  .firstbox_cont{
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .secondbox{
    margin-top: 40px;
    width:80vw;
    height: 250px;
    border-radius:20px;
    box-shadow: rgba(65, 194, 172, 0.26) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    background-color:white;
    opacity:0.95;
    border-left: 11px solid #0011FF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .secondbox_cont{
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  #promote_cont{
    font-family:Lora;
    font-size:130%;
    text-align:center;
    color: #0011FF;
  }
  #build_btn{
    font-family: Lora;
    font-size: 18px;
    color:white;
    font-weight: bold;
    background-color:#0099FF;
    outline:none;
    border:none;
    border-top-left-radius:20px;
    border-bottom-right-radius:20px;
    width: 60vw;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    height: 45px;
  }
  #Estimate_btn{
    margin-top: 20px;
    cursor: pointer;
    font-family: Lora;
    font-size: 18px;
    color:white;
    font-weight: bold;
    background-color:#00CC59;
    outline:none;
    border:none;
    border-top-right-radius:20px;
    border-bottom-left-radius:20px;
    width: 60vw;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    height: 45px;
  }
  #Estimate_btn:hover{
    background-color:white;
    color: #00CC59;
    border: 2px solid #00CC59;
  }
  #build_btn:hover{
    background-color:white;
    color: #0099FF;
    border: 2px solid #0099FF;
  }

  .home_resize{
    display: none !important;

  }
  .home_container2{
    display: none !important;
  }

  .line{
    display:none
  }
  footer{
    display:none;
    width:0
  }
  .copyright{
    display:none;
    width:0;
  }
  .address_nav{
    display: none
  }
  .bottom_logo{
    display:none
  }
  /* #Inner_image{
    display:none;
  } */

  #Inner_content{
    width:calc(55vw - 130px);
  }
  #Inner_text1{
    font-size:100%
  }

  #Inner_image{
    width:45vw;
  }
  #text1{
    font-size:130%;
    text-align:center
  }
  #text0{
    font-size: 150%;
  }
  .popup-content{
    font-size: 80%;
  }

}

@media screen and (max-width: 820px) and (min-width: 551px) {
  #Inner_text1{
    font-size:26px;
  }
  #text1{
    font-size:16px
  }
}