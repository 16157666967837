@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.loading{
    display:flex;
    justify-content:center;
    align-items:center;
    width:90vw;
    height:80vh;
    background-color:rgb(253, 253, 253)
}
.loading img{
    width: 200px;
    height: 200px;
}
.App_Body{
    margin-left:70px;
    margin-right:70px;
}
.popup-content h4{
    font-family: Lora;
}
.popup-content h4::after{
    content:" *";
    color:red;
}
.Cartlogo{
    display:flex;
    color: #FF8800;
    width:98%;
    justify-content: flex-end;
    animation: move 2s ease-in;  
    align-items: center;
}    
.Estimation_button{
    display: flex;
    width:150px;
    height:30px;

}
#whatyouneed::after{
    content:" *";
    color:red;
}
.SignOutButton{
    position: absolute;
    top: 90px;
    justify-content:center;
    align-items: center;
    width:100px;
    height:25px
}
.SignOutButton button{
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    border-radius: 5px;
    font-weight:800;
    color:rgb(255, 255, 255);
    cursor:pointer;
    font-family: Lora;
    font-size: 14px;
    background-color: #5890FF;
}

.Estimation_button button{
    outline: none;
    border: none;
    border-radius: 5px;
    font-weight:500;
    color:white;
    cursor:pointer;
    font-family: lora;
    font-size: 16px;
}
.LoginButton_Container{
    display: flex;
    max-width: 90vw;
    min-width: 200px;
    height: 80vh;
    justify-content:center;
    align-items: center;    
    flex-direction: column;
    font-size: 12px;
}

.LoginButton{
    width:200px;
}
@keyframes move{
    0%{
        transform:translateX(-100%)
    }
    
}

#CartTotalCount{
    font-family:Lora;
    padding-left: 8px;
}

.Add-on{
    
    padding-top:20px ;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100- 140px);
    background-color:rgba(252, 252, 252, 0.514);
    min-height: 100vh;
    height:100%
}

#addon_heading{
    font-family: Lora;
    font-size: 16px;
    color:rgb(21, 8, 43)
}
.Items{
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    
}
.Login_Items{
    cursor:pointer;
    font-size: 12px;
    font-family: Varela Round;
    display: flex;
    margin-bottom:20px;
    margin-right: 20px;
    justify-content:center;
    padding-top:20px;
    padding-left: 20px;
    padding-right:20px;
    padding-bottom: 20px;
    border-radius:10px;
    background-color: white;
    width:100px;
    height:100px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.cartcheckbox_container{
    display: flex;
    flex-direction: column;
}

.cart_check{
    width: calc(100% + 10px);
    display: flex;
    justify-content: flex-end;
}

.checkbox_class{
    width: 10px;
    outline:none;
}

#studioimage{
    width:130px;
    height:60px;
    display:flex;
    justify-content:center;
    align-items:center;
}

#login_text{
    
    width:130px;
    padding-top: 10px;
    
    display:flex;
    justify-content:center;
    overflow:hidden
}

#facebookbutton{
    height:50px;
    width: 200px;
}

#googlebutton{
    height:50px;
    width: 200px;
}

#facebooklogin{
    display:flex;
    align-items:center;
    font-size: 14px;
    height:40px;
    width: 200px;
    background-color: #5890FF;
    font-weight: 600;
    outline:none;
    border:none;
    color: white;
    font-family: Open Sans;
    cursor:pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
}

#facebooklogin:hover{
    background-color: #4267B2;
    
}

#googlelogin{
    display:flex;
    font-size: 14px;
    font-family: Open Sans;
    align-items:center;
    height:40px;
    width: 200px;
    outline:none;
    border:none;
    font-weight: 600;
    color: #4285F4;
    background-color:white;
    cursor:pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
}
#googlelogin:hover{
    background-color:whitesmoke
}

#signoutpara{
    color:rgb(8, 8, 53);
    font-size: 10px;
}
@media only screen and (max-width:600px){
    .Items{
        justify-content:center;
        
    }
    .Login_Items{
        width:60px;
        height:60px;
        font-size: 10px;
    }
    #login_text{
    
        width:130px;
        padding-top: 10px;
        font-size:9px;
        display:flex;
        justify-content:center;
        overflow:hidden
    }
    #studioimage{
        width:130px;
        height:40px;
        display:flex;
        justify-content:center;
        align-items:center;
    }
}


@media only screen and (max-width:750px){
    .App_Body{
        margin-left:2px;
        margin-right:2px;
    }
}