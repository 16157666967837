@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.SecondBody{
    width: 100%;
    height:fit-content;
    display:flex;
    flex-wrap: wrap;
    justify-content:center;
}

.First_body{
    /* width: 100vw; */
    height:calc(100vh - 88px);
}

.Inner_First_body{
    margin-left: 70px;
    margin-right:70px;
    height:calc(100vh - 88px);
    width: calc(100vw - 140px);
}

.First{
    display: flex;
    justify-content:space-between;
    align-items: space-around;
    min-height:calc(100vh - 220px);
    flex-wrap: wrap;
}

.Help{
    display: flex;
    justify-content: center;
    font-size: 140%;
    font-family:Varela Round;
    color:rgb(76, 33, 126);
}

.Idea{
    margin-top: 40px;
    width:600px;
    height:350px;
    display: flex;
    background-color:rgb(123, 176, 255);
    align-items: center;
}

.Idea:hover{
    box-shadow: 0px 3px 3px 3px rgba(196, 196, 196, 0.993)
}

.Idea #Idea_heading{    
    font-family: Indie Flower;
    font-size:125%;
    color:rgb(255, 64, 30);
    padding-right: 18px;
}

.Idea #Idea_para{
    padding-right: 18px;
    font-family: Lora;
    color:rgb(255, 255, 255);
    font-size: 18px
}

.Idea #Idea_image{
    width: 600px;
    height:250px;
    display: flex;
    align-items:center;

}

.VideoConf{
    margin-top: 40px;
    width:600px;
    height:350px;
    display: flex;
    background-color:rgb(255, 223, 79);
    align-items: center;
}

.VideoConf:hover{
    box-shadow: 0px 3px 3px 3px rgba(196, 196, 196, 0.993);
}

#VideoConf_heading{
    max-height: 350px;
    font-family: Indie Flower;
    font-size:125%;
    color:rgb(30, 109, 255);
    padding-right: 18px;
}

#VideoConf_para{
    padding-right: 16px;
    font-family: Lora;
    color:rgb(255, 255, 255);
    font-size: 18px
}

#VideoConf_image{
    width:600px;
    height:250px;
    display: flex;
    align-items:center;
    padding-right: 25px;
    padding-left:25px
}

.Our_Services{
    display: flex;
    height: 100px;
    width:calc(100vw - 140px);
    justify-content:center;
    font-family:Varela Round;
    color:rgb(76, 33, 126);
}

.AIBot{
    padding-left: 10px;
    padding-right: 10px;
    width:200px;
    height: 240px;
    background-image: url(./AI_Bot.svg);
    background-repeat: no-repeat;
    background-size:200px;
}

.AIBot_Content{
    width:200px;
    display: flex;
    justify-content: center;
    padding-top:180px;
}

.Mobile{
    width:200px;
    padding-left: 10px;
    padding-right: 10px;
    height:250px;
}
.Mobile img{
    height:180px
}
.Mobile_Content{
    display: flex;
    justify-content: center;
}

.MailAutomation{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    width: 200px;
    height:210px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items:center

}
.MailAutomation img{
    height: 200px;
    width: 200px;
}
.MailAutomation_Content{
    padding-top: 10px;
    display: flex;
    justify-content: center;
}

.Videocall{
    padding-left: 10px;
    padding-right: 10px;
    padding-top:40px;
    width: 200px;
    height:250px;
}

.Videocall_Content{
    padding-top: 10px;
    display: flex;
    justify-content: center;
}

.Image_Processing{
    padding-left: 20px;
    padding-right: 10px;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:250px
}

.IOT{
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:235px;
}
.IOT img{
    width: 200px;
    height: 130px;
}

.API{
    padding-left: 10px;
    padding-right: 10px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:250px
}
.API img{
    width: 160px;
    height: 120px;
}

.GoogleAssistant{
    padding-left: 10px;
    padding-right: 10px;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:250px
}
.GoogleAssistant img{
    height: 140px;
}

.Alexa{
    padding-left: 10px;
    padding-right: 10px;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:250px
}
.Alexa img{
    height: 150px;
}
.Websitedev{
    padding-left: 10px;
    padding-right: 10px;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:250px
}
.Websitedev img{
    width:200px;
    height: 150px;
}


.Image_Processing img{
    width: 200px
}

.Image_Processing_Content{
    display: flex;
    justify-content: center;
}

@media only screen and (max-width:1340px) {
    .First{
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width:1100px){
    
    .Idea #Idea_para{
        font-size:16px;
    }
    .VideoConf #VideoConf_para{
        font-size :16px;
    }
}

@media only screen and (max-width:650px){
    .Inner_First_body{
        margin-left: none;
        margin-right:none
    }
    .Help{
        font-size: 14px;
    }
    .First_body{
        justify-content: center;
        
    }

    .First{
        display: flex;
        justify-content:center;
        align-items: space-around;
        min-height:calc(100vh - 220px);
        flex-wrap: wrap;
        max-width: calc(100vw - 100px);
    }

    #Idea_image img{
        height: 250px;
        width: 100%
    }
    #Idea_heading{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-right:15px;
        margin-left:25px;
    }
    .Idea{
        min-width: calc(100vw - 22px);
        margin-right:25px;
        margin-left:25px;
        height:150%;
        flex-wrap: wrap;
    }
    .VideoConf{
        min-width: calc(100vw - 22px);
        height: 250px;
        height:150%;
        flex-wrap: wrap
    }
    #VideoConf_image img{
        height: 150px;
        width: 100%
    }
    #VideoConf_image{
        display: flex;
        justify-content:center;
        height: 200px;
        width: 400px
    }
    #VideoConf_heading{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-right:15px;
        margin-left:30px;
    }
}