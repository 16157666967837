@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@600&family=Original+Surfer&display=swap');

.navbar_container{
    display: flex;
    align-items: center;
    background-color: rgb(254, 253, 255);
    height:70px
   
}
.NavLeft_container{
    font-family: Cairo;
    display: flex;
    align-items: center;
    justify-content:center;
    width:100%;
    margin-right: 70px;
}

#logo{
    margin-left: 70px;
    width:140px;
}

#home{
    color:black;
    text-decoration: none;
    padding-right: 20px;
    cursor: pointer;
}
#home:hover{
    color:rgb(77, 77, 206)
}

#services{
    color:black;
    text-decoration: none;
    padding-right: 20px;
    cursor: pointer
}
#services:hover{
    color:rgb(240, 147, 25)
}

#studio{
    color:black;
    text-decoration: none;
    padding-right: 50px;
    cursor: pointer
}

#items{
    color:black;
    text-decoration: none;
    font-size: 75%;
    cursor: pointer
}
#studio:hover{
    color:rgb(43, 174, 226)
}

.Mobile_Nav_item{
    font-family: Cairo;
    height:100%;
    display:flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;

}
@media only screen and (min-width:551px) {
    .Mobile_Nav{
        display:none
    }
    
  }

@media only screen and (max-width:550PX){
    .NavLeft_container{
        display:none;
        width:0
    }
    .Mobile_Nav{
        position: absolute;
        right: 30px;
        
    }
    #logo{
        width:120px
    }

}