@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@600&family=Original+Surfer&display=swap);

.App {
  margin: 0;
  padding: 0;
}
body{
  margin: 0;
  padding: 0
}
.home_container1{
    margin-left: 85px;
    margin-right: 75px;
    display: -webkit-flex;
    display: flex;
    height:calc(100vh - 60px);
    -webkit-align-items: center;
            align-items: center;
    
    
}
.mobile_view_1{
  display:none;
}
.mobile_view_2{
  display:none;
}
.home_container2{
    background-color: rgb(239, 251, 255);
    display: -webkit-flex;
    display: flex;
    height:100vh;
    width:auto;
    
}
#imageofrocket{
  /* background-color:aqua; */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height:500px;
  width: 500px
}
#imageofrocket img{
  height:350px;
  width: 350px
}
.Inner{
  display: -webkit-flex;
  display: flex;
  margin-left: 75px;
  margin-right: 75px;
  height:100vh;
  width:100vw;
  -webkit-align-items: center;
          align-items: center;
}
#Inner_image{
  width:45vw;
  margin-right: 50px;
}

#Inner_content{
  width:calc(50vw - 130px);
}
#Inner_text1{
  font-family:Lora;
  font-size:200%
}

#Top_content{
    width: 50vw;
}


#text0 {
  font-family:Lora;
    font-size:300%;
    color:rgb(64, 26, 107);
  }
  #text0:after {
    content:"";
    -webkit-animation: spin 12s infinite;
            animation: spin 12s infinite;
    font-size: 85%;
  }
  @-webkit-keyframes spin {
    0% { content: " AI Application";opacity: 0.25; color:#0099FF}

    5% { content: " Web Application"; opacity: 1;color:#0011FF }
    22% { content: " Web Application"; opacity: 1;color:#0011FF }
    25% { content: " Web Application"; opacity: 0.5 }
    28% { content: " Web Application"; opacity: 0.25 }

    30% { content: " Mobile Application";opacity: 1;color:#FF8800}
    52% { content: " Mobile Application";opacity: 1;color:#FF8800 }
    55% { content: " Mobile Application";opacity: 0.5 }
    58% { content: " Mobile Application";opacity: 0.25 }

    60% { content: " IOT Application";opacity: 1;color:#00CC59 }
    72% { content: " IOT Application";opacity: 1;color:#00CC59 }
    75% { content: " IOT Application";opacity: 0.5 }
    78% { content: " IOT Application";opacity: 0.25 }

    80% { content: " AI Application";opacity: 1;color:#0099FF }
    92% { content: " AI Application";opacity: 1;color:#0099FF }
    95% { content: " AI Application";opacity: 0.5 }
    100% { content: " AI Application";opacity: 0.25}
  }
  @keyframes spin {
    0% { content: " AI Application";opacity: 0.25; color:#0099FF}

    5% { content: " Web Application"; opacity: 1;color:#0011FF }
    22% { content: " Web Application"; opacity: 1;color:#0011FF }
    25% { content: " Web Application"; opacity: 0.5 }
    28% { content: " Web Application"; opacity: 0.25 }

    30% { content: " Mobile Application";opacity: 1;color:#FF8800}
    52% { content: " Mobile Application";opacity: 1;color:#FF8800 }
    55% { content: " Mobile Application";opacity: 0.5 }
    58% { content: " Mobile Application";opacity: 0.25 }

    60% { content: " IOT Application";opacity: 1;color:#00CC59 }
    72% { content: " IOT Application";opacity: 1;color:#00CC59 }
    75% { content: " IOT Application";opacity: 0.5 }
    78% { content: " IOT Application";opacity: 0.25 }

    80% { content: " AI Application";opacity: 1;color:#0099FF }
    92% { content: " AI Application";opacity: 1;color:#0099FF }
    95% { content: " AI Application";opacity: 0.5 }
    100% { content: " AI Application";opacity: 0.25}
  }

#text1 {
  font-family:Lora;
  font-size:125%;
  color:rgb(18, 18, 97);
  height: 80px;
}

#text1:after {
  content:"";
  -webkit-animation: text1_spin 12s infinite;
          animation: text1_spin 12s infinite;
  color: #FF8800;
}
  
  @-webkit-keyframes text1_spin {
    0% {content: "We will help you to develop your idea."}
    65% { content: "We are here to develop your fantasy into more powerful application.";}
    100%{content: "We will also help you in Digital Marketing"}
  }
  
  @keyframes text1_spin {
    0% {content: "We will help you to develop your idea."}
    65% { content: "We are here to develop your fantasy into more powerful application.";}
    100%{content: "We will also help you in Digital Marketing"}
  }
 
  #text10 {
    font-family:Lora;
    font-size:150%;
    color:rgb(18, 18, 97);
    
  }
  #text11 {
    font-family:Lora;
    font-size:150%;
    color:rgb(18, 18, 97);
    
  }
  
  /* #text10:after {
    content:" ";
    animation: text10_spin 12s infinite;
    font-size:90%
  } */
    
    @-webkit-keyframes text10_spin {
      0% {content: "AI Application";color:#0099FF}
      33% { content: "Web Application";color:#0011FF}
      67% {content: "IOT Application";color:#FF8800}
      100%{content: "Mobile Application";color:#00CC59}
    }
    
    @keyframes text10_spin {
      0% {content: "AI Application";color:#0099FF}
      33% { content: "Web Application";color:#0011FF}
      67% {content: "IOT Application";color:#FF8800}
      100%{content: "Mobile Application";color:#00CC59}
    }

.address{
  height:8vh;
  background-color:rgb(254, 253, 255);
  display:-webkit-flex;
  display:flex;
  /* padding-bottom: 20px; */
}
#GetEstimation{
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  outline:none;
  width:170px;
  font-size:16px;
  height: 40px;
  border: none;
  background-color:#00CC59;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color:white;
  cursor:pointer
}
#GetEstimation:hover{
  background-color:#FF8800
}
.mail{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color:rgb(18, 18, 97);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-top: 10px;
  font-size:70%;
  margin-left: 70px;
  margin-right:70px;
  
}
.mail img{
  margin-right: 10px;
}  
footer{
  display: -webkit-flex;
  display: flex;
  background-color:rgb(254, 253, 255);
  height:4vh;
}

.copyright{
  padding-top:3px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-left:70px;
  margin-right: 70px;
  font-size: 10px;
  width: 100%;
}

#Top_button{
  width:200px;
  height:40px;
  cursor: pointer;
  outline:none;
  border:none;
  background-color:rgb(41, 191, 236);
  border-top-left-radius:15px;
  border-bottom-right-radius:15px;
  color:white;
  font-size:medium;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
#Top_button:hover{
  background-color:tomato;
}
#Inner_button{
  cursor: pointer;
  outline:none;
  border:none;
  background-color:#0099FF;
  width:150px;
  height:35px;
  font-size:14px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color:white;
}
#Inner_button:hover{
  background-color:#0011FF
}

.address_nav{
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content:space-around;
          justify-content:space-around;
  width: 50%;
  color:rgb(18, 18, 97);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-top: 15px;
  font-size:70%;
}

.bottom_logo{
  padding-top:18px;
  width: 250px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content:flex-end;
          justify-content:flex-end;
}

.line{
  margin-left: 25px;
  margin-right: 40px;
  width: calc(100vw - 80px);
  background-color: rgba(180, 180, 180, 0.932);
  height:1px
}



@media only screen and (max-width: 800px){
  #imageofrocket{
    width: 300px;
    height:300px;
  }
  #imageofrocket img{
    width: 280px;
    height:280px;
  }
  #text0{
    font-size:170%
  }
}
@media only screen and (max-width: 700px){
  #text1{
    height: 100px
  }
  #imageofrocket{
    width: 250px;
    height:250px;
  }
  #imageofrocket img{
    width: 250px;
    height:250px;
  }

  #text0{
    font-size: 150%;
  }
}


.popup-content{
  font-family: Lora;
}

#YourName{
  display: -webkit-flex;
  display: flex;
}

#YourName input{
  height: 25px;
  width:auto;
  border:none;
  border-bottom: 1.5px solid rgb(83, 83, 83);
  background-color:transparent;
  color:rgb(38, 35, 73);
}

#YourName input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 91, 209);
}

#inp1{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left:20px;
}

#projectName{
  display: -webkit-flex;
  display: flex;
}
#projectName input{
  height: 25px;
  width:auto;
  border:none;
  border-bottom: 1.5px solid rgb(83, 83, 83);
  background-color:transparent;
  color:rgb(38, 35, 73);
  
}

#projectName input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 91, 209);
}

#inp2{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left:20px
}

#companyName{
  display: -webkit-flex;
  display: flex;
}

#companyName input{
  height: 25px;
  width:auto;
  border:none;
  border-bottom: 1.5px solid rgb(83, 83, 83);
  background-color:transparent;
  color:rgb(38, 35, 73);
}

#companyName input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 91, 209);
}

#inp3{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left:20px
}
#Contact{
  display: -webkit-flex;
  display: flex;
}
#Contact input{
  height: 25px;
  width: auto;
  border:none;
  border-bottom: 1.5px solid rgb(83, 83, 83);
  background-color:transparent;
  color:rgb(38, 35, 73);
}

#Contact input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 91, 209);
}

#inp4{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left:20px
}

#EmailId{
  display: -webkit-flex;
  display: flex;
}
#EmailId input{
  height: 25px;
  width: auto;
  border:none;
  border-bottom: 1.5px solid rgb(83, 83, 83);
  background-color:transparent;
  color:rgb(38, 35, 73);
}

#EmailId input:focus{
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(0, 91, 209);
}

#inp5{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left:20px
}

#projectIdea{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column
}
#projectIdea textarea{
  height: 50px;
  width:300px;
  background-color:transparent;
  color:rgb(38, 35, 73);
  /* border: */
}
#inp6{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left:20px
}

#need{
  display: -webkit-flex;
  display: flex;
}

#first{
  width: 45vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 20px;
}


#first-inner{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.Submit_Button{
  display: -webkit-flex;
  display: flex;
  width:100%;
  padding-top: 20px;
  -webkit-justify-content: center;
          justify-content: center;
}
#Button_Attributes{
  width:80px;
  height:25px;
  background-color:rgb(0, 100, 250);
  border-radius: 6px;
  outline:none;
  border:none;
  color:rgb(215, 237, 255);
}
@media only screen and (max-width: 550px){
  .mobile_view_1{
    display: -webkit-flex;
    display: flex;
    
  }
  .body_first{
    margin-top:7;
    margin: 8%;
    background-color:white;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
  }
  
  #text10{
    width:100%;
    text-align: center;
  }
  #text11{
    height:2px;
    width:100%;
    text-align: center;
  }
  .body1{
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    width: 100%;
  }
  .body1 #body1_h2{
    font-family:Lora;
    font-size:145%;
    color:rgb(64, 26, 107);
  }
  .mobile_view_2{
    display: -webkit-flex;
    display: flex;
    background-image: url(/static/media/bg_color.06cc439c.svg);
    background-position: flex-start;
    background-repeat: no-repeat;
  }
  .body_second{
    display: -webkit-flex;
    display: flex;
    margin: 7%;
    width:100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .firstbox{
    margin-top: 80px;
    width:90%;
    height: 250px;
    border-radius:20px;
    box-shadow: rgba(48, 48, 255, 0.418) 0px 50px 100px -20px, rgba(0, 0, 0, 0.562) 0px 30px 60px -30px;
    background-color:white;
    opacity:1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border-left:11px solid #FF8800;
  }
  .swiper_Div{
    background-color: white;
    border-radius: 20px;
    border-left: 11px solid tomato;
    width:80vw;
    min-height: 250px;
    height: 250px;
   
  }
  .swiper{
    width:80vw;
    height: 250px;
    border-radius: 20px;
    margin-top: 50px;
    background-color:white;
    
  }
  
  #checkbox_con{
    font-size: 12px;
    /* font-family:; */
  }
  .swippingContent{
    height:75%;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 30px;
 
  }
  .firstbox_cont{
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .secondbox{
    margin-top: 40px;
    width:80vw;
    height: 250px;
    border-radius:20px;
    box-shadow: rgba(65, 194, 172, 0.26) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    background-color:white;
    opacity:0.95;
    border-left: 11px solid #0011FF;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .secondbox_cont{
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  #promote_cont{
    font-family:Lora;
    font-size:130%;
    text-align:center;
    color: #0011FF;
  }
  #build_btn{
    font-family: Lora;
    font-size: 18px;
    color:white;
    font-weight: bold;
    background-color:#0099FF;
    outline:none;
    border:none;
    border-top-left-radius:20px;
    border-bottom-right-radius:20px;
    width: 60vw;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    height: 45px;
  }
  #Estimate_btn{
    margin-top: 20px;
    cursor: pointer;
    font-family: Lora;
    font-size: 18px;
    color:white;
    font-weight: bold;
    background-color:#00CC59;
    outline:none;
    border:none;
    border-top-right-radius:20px;
    border-bottom-left-radius:20px;
    width: 60vw;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    height: 45px;
  }
  #Estimate_btn:hover{
    background-color:white;
    color: #00CC59;
    border: 2px solid #00CC59;
  }
  #build_btn:hover{
    background-color:white;
    color: #0099FF;
    border: 2px solid #0099FF;
  }

  .home_resize{
    display: none !important;

  }
  .home_container2{
    display: none !important;
  }

  .line{
    display:none
  }
  footer{
    display:none;
    width:0
  }
  .copyright{
    display:none;
    width:0;
  }
  .address_nav{
    display: none
  }
  .bottom_logo{
    display:none
  }
  /* #Inner_image{
    display:none;
  } */

  #Inner_content{
    width:calc(55vw - 130px);
  }
  #Inner_text1{
    font-size:100%
  }

  #Inner_image{
    width:45vw;
  }
  #text1{
    font-size:130%;
    text-align:center
  }
  #text0{
    font-size: 150%;
  }
  .popup-content{
    font-size: 80%;
  }

}

@media screen and (max-width: 820px) and (min-width: 551px) {
  #Inner_text1{
    font-size:26px;
  }
  #text1{
    font-size:16px
  }
}
:root {
    --swiper-navigation-size: 44px;
}
.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 60%;
    width: calc(44px/ 44 * 27);
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: 44px;
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (44px/ 2));
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 10;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: tan;
}
.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none;
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 20px;
    text-transform: none!important;
    letter-spacing: 0;
    text-transform: none;
    -webkit-font-feature-settings: normal, ;
            font-feature-settings: normal, ;
    font-variant: normal;
    font-variant: initial;
    line-height: 1;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: 'prev'}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
    content: 'next'}
.swiper-button-next.swiper-button-white, .swiper-button-prev.swiper-button-white {
    --swiper-navigation-color: #ffffff;
}
.swiper-button-next.swiper-button-black, .swiper-button-prev.swiper-button-black {
    --swiper-navigation-color: #000000;
}
.swiper-button-lock {
    display: none;
}
.loading{
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    width:90vw;
    height:80vh;
    background-color:rgb(253, 253, 253)
}
.loading img{
    width: 200px;
    height: 200px;
}
.App_Body{
    margin-left:70px;
    margin-right:70px;
}
.popup-content h4{
    font-family: Lora;
}
.popup-content h4::after{
    content:" *";
    color:red;
}
.Cartlogo{
    display:-webkit-flex;
    display:flex;
    color: #FF8800;
    width:98%;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-animation: move 2s ease-in;
            animation: move 2s ease-in;  
    -webkit-align-items: center;  
            align-items: center;
}    
.Estimation_button{
    display: -webkit-flex;
    display: flex;
    width:150px;
    height:30px;

}
#whatyouneed::after{
    content:" *";
    color:red;
}
.SignOutButton{
    position: absolute;
    top: 90px;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;
    width:100px;
    height:25px
}
.SignOutButton button{
    height: 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    outline: none;
    border: none;
    border-radius: 5px;
    font-weight:800;
    color:rgb(255, 255, 255);
    cursor:pointer;
    font-family: Lora;
    font-size: 14px;
    background-color: #5890FF;
}

.Estimation_button button{
    outline: none;
    border: none;
    border-radius: 5px;
    font-weight:500;
    color:white;
    cursor:pointer;
    font-family: lora;
    font-size: 16px;
}
.LoginButton_Container{
    display: -webkit-flex;
    display: flex;
    max-width: 90vw;
    min-width: 200px;
    height: 80vh;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items: center;
            align-items: center;    
    -webkit-flex-direction: column;    
            flex-direction: column;
    font-size: 12px;
}

.LoginButton{
    width:200px;
}
@-webkit-keyframes move{
    0%{
        -webkit-transform:translateX(-100%);
                transform:translateX(-100%)
    }
    
}
@keyframes move{
    0%{
        -webkit-transform:translateX(-100%);
                transform:translateX(-100%)
    }
    
}

#CartTotalCount{
    font-family:Lora;
    padding-left: 8px;
}

.Add-on{
    
    padding-top:20px ;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100- 140px);
    background-color:rgba(252, 252, 252, 0.514);
    min-height: 100vh;
    height:100%
}

#addon_heading{
    font-family: Lora;
    font-size: 16px;
    color:rgb(21, 8, 43)
}
.Items{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content:flex-start;
            justify-content:flex-start;
    
}
.Login_Items{
    cursor:pointer;
    font-size: 12px;
    font-family: Varela Round;
    display: -webkit-flex;
    display: flex;
    margin-bottom:20px;
    margin-right: 20px;
    -webkit-justify-content:center;
            justify-content:center;
    padding-top:20px;
    padding-left: 20px;
    padding-right:20px;
    padding-bottom: 20px;
    border-radius:10px;
    background-color: white;
    width:100px;
    height:100px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.cartcheckbox_container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.cart_check{
    width: calc(100% + 10px);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.checkbox_class{
    width: 10px;
    outline:none;
}

#studioimage{
    width:130px;
    height:60px;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
}

#login_text{
    
    width:130px;
    padding-top: 10px;
    
    display:-webkit-flex;
    
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    overflow:hidden
}

#facebookbutton{
    height:50px;
    width: 200px;
}

#googlebutton{
    height:50px;
    width: 200px;
}

#facebooklogin{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    font-size: 14px;
    height:40px;
    width: 200px;
    background-color: #5890FF;
    font-weight: 600;
    outline:none;
    border:none;
    color: white;
    font-family: Open Sans;
    cursor:pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
}

#facebooklogin:hover{
    background-color: #4267B2;
    
}

#googlelogin{
    display:-webkit-flex;
    display:flex;
    font-size: 14px;
    font-family: Open Sans;
    -webkit-align-items:center;
            align-items:center;
    height:40px;
    width: 200px;
    outline:none;
    border:none;
    font-weight: 600;
    color: #4285F4;
    background-color:white;
    cursor:pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
}
#googlelogin:hover{
    background-color:whitesmoke
}

#signoutpara{
    color:rgb(8, 8, 53);
    font-size: 10px;
}
@media only screen and (max-width:600px){
    .Items{
        -webkit-justify-content:center;
                justify-content:center;
        
    }
    .Login_Items{
        width:60px;
        height:60px;
        font-size: 10px;
    }
    #login_text{
    
        width:130px;
        padding-top: 10px;
        font-size:9px;
        display:-webkit-flex;
        display:flex;
        -webkit-justify-content:center;
                justify-content:center;
        overflow:hidden
    }
    #studioimage{
        width:130px;
        height:40px;
        display:-webkit-flex;
        display:flex;
        -webkit-justify-content:center;
                justify-content:center;
        -webkit-align-items:center;
                align-items:center;
    }
}


@media only screen and (max-width:750px){
    .App_Body{
        margin-left:2px;
        margin-right:2px;
    }
}
.ProjectPopup{
    position:fixed;
    top:0;
    left:0;
    width: 100vw;
    height:100vh;
    background-color: rgba(170, 170, 170, 0.486);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    z-index:2;
}

.inner-ProjectPopup{
    margin-top: 80px;
    position:relative;
    padding: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    padding-left: 50px;
    background-image:linear-gradient(rgb(255, 255, 255),rgb(242, 255, 255));
    opacity: 0.98;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 600px;
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: visible;
}

.Closebutton{
    width:100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:flex-end;
            justify-content:flex-end;
    
}
#close{
    background-color:rgb(255, 255, 255);
    border:none;
    border-radius: 25px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border: 0.5px solid gray;
    padding: 4px;
}
#close:hover{
    height: 23px;
    width: 23px;
    background-color:rgb(233, 227, 255);
    border: 0.5px solid rgb(115, 176, 255);
}
@media only screen and (max-width:500px){
    .inner-ProjectPopup{
        max-width:90vw;
        padding-left: 30px;
    }
}
.SecondBody{
    width: 100%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content:center;
            justify-content:center;
}

.First_body{
    /* width: 100vw; */
    height:calc(100vh - 88px);
}

.Inner_First_body{
    margin-left: 70px;
    margin-right:70px;
    height:calc(100vh - 88px);
    width: calc(100vw - 140px);
}

.First{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    -webkit-align-items: space-around;
            align-items: space-around;
    min-height:calc(100vh - 220px);
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.Help{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 140%;
    font-family:Varela Round;
    color:rgb(76, 33, 126);
}

.Idea{
    margin-top: 40px;
    width:600px;
    height:350px;
    display: -webkit-flex;
    display: flex;
    background-color:rgb(123, 176, 255);
    -webkit-align-items: center;
            align-items: center;
}

.Idea:hover{
    box-shadow: 0px 3px 3px 3px rgba(196, 196, 196, 0.993)
}

.Idea #Idea_heading{    
    font-family: Indie Flower;
    font-size:125%;
    color:rgb(255, 64, 30);
    padding-right: 18px;
}

.Idea #Idea_para{
    padding-right: 18px;
    font-family: Lora;
    color:rgb(255, 255, 255);
    font-size: 18px
}

.Idea #Idea_image{
    width: 600px;
    height:250px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items:center;
            align-items:center;

}

.VideoConf{
    margin-top: 40px;
    width:600px;
    height:350px;
    display: -webkit-flex;
    display: flex;
    background-color:rgb(255, 223, 79);
    -webkit-align-items: center;
            align-items: center;
}

.VideoConf:hover{
    box-shadow: 0px 3px 3px 3px rgba(196, 196, 196, 0.993);
}

#VideoConf_heading{
    max-height: 350px;
    font-family: Indie Flower;
    font-size:125%;
    color:rgb(30, 109, 255);
    padding-right: 18px;
}

#VideoConf_para{
    padding-right: 16px;
    font-family: Lora;
    color:rgb(255, 255, 255);
    font-size: 18px
}

#VideoConf_image{
    width:600px;
    height:250px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items:center;
            align-items:center;
    padding-right: 25px;
    padding-left:25px
}

.Our_Services{
    display: -webkit-flex;
    display: flex;
    height: 100px;
    width:calc(100vw - 140px);
    -webkit-justify-content:center;
            justify-content:center;
    font-family:Varela Round;
    color:rgb(76, 33, 126);
}

.AIBot{
    padding-left: 10px;
    padding-right: 10px;
    width:200px;
    height: 240px;
    background-image: url(/static/media/AI_Bot.3bf33b14.svg);
    background-repeat: no-repeat;
    background-size:200px;
}

.AIBot_Content{
    width:200px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top:180px;
}

.Mobile{
    width:200px;
    padding-left: 10px;
    padding-right: 10px;
    height:250px;
}
.Mobile img{
    height:180px
}
.Mobile_Content{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.MailAutomation{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    width: 200px;
    height:210px;
    padding-bottom: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items:center;
            align-items:center

}
.MailAutomation img{
    height: 200px;
    width: 200px;
}
.MailAutomation_Content{
    padding-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.Videocall{
    padding-left: 10px;
    padding-right: 10px;
    padding-top:40px;
    width: 200px;
    height:250px;
}

.Videocall_Content{
    padding-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.Image_Processing{
    padding-left: 20px;
    padding-right: 10px;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:250px
}

.IOT{
    padding-left: 10px;
    padding-right: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:235px;
}
.IOT img{
    width: 200px;
    height: 130px;
}

.API{
    padding-left: 10px;
    padding-right: 10px;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:center;
            justify-content:center;
    -webkit-align-items:center;
            align-items:center;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:250px
}
.API img{
    width: 160px;
    height: 120px;
}

.GoogleAssistant{
    padding-left: 10px;
    padding-right: 10px;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:250px
}
.GoogleAssistant img{
    height: 140px;
}

.Alexa{
    padding-left: 10px;
    padding-right: 10px;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:250px
}
.Alexa img{
    height: 150px;
}
.Websitedev{
    padding-left: 10px;
    padding-right: 10px;
    padding-top:40px;
    min-width: 200px;
    width: 200px;
    height:250px
}
.Websitedev img{
    width:200px;
    height: 150px;
}


.Image_Processing img{
    width: 200px
}

.Image_Processing_Content{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

@media only screen and (max-width:1340px) {
    .First{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }
}

@media only screen and (max-width:1100px){
    
    .Idea #Idea_para{
        font-size:16px;
    }
    .VideoConf #VideoConf_para{
        font-size :16px;
    }
}

@media only screen and (max-width:650px){
    .Inner_First_body{
        margin-left: none;
        margin-right:none
    }
    .Help{
        font-size: 14px;
    }
    .First_body{
        -webkit-justify-content: center;
                justify-content: center;
        
    }

    .First{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content:center;
                justify-content:center;
        -webkit-align-items: space-around;
                align-items: space-around;
        min-height:calc(100vh - 220px);
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        max-width: calc(100vw - 100px);
    }

    #Idea_image img{
        height: 250px;
        width: 100%
    }
    #Idea_heading{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        width: 100%;
        margin-right:15px;
        margin-left:25px;
    }
    .Idea{
        min-width: calc(100vw - 22px);
        margin-right:25px;
        margin-left:25px;
        height:150%;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .VideoConf{
        min-width: calc(100vw - 22px);
        height: 250px;
        height:150%;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap
    }
    #VideoConf_image img{
        height: 150px;
        width: 100%
    }
    #VideoConf_image{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content:center;
                justify-content:center;
        height: 200px;
        width: 400px
    }
    #VideoConf_heading{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        width: 100%;
        margin-right:15px;
        margin-left:30px;
    }
}
.navbar_container{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: rgb(254, 253, 255);
    height:70px
   
}
.NavLeft_container{
    font-family: Cairo;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content:center;
            justify-content:center;
    width:100%;
    margin-right: 70px;
}

#logo{
    margin-left: 70px;
    width:140px;
}

#home{
    color:black;
    text-decoration: none;
    padding-right: 20px;
    cursor: pointer;
}
#home:hover{
    color:rgb(77, 77, 206)
}

#services{
    color:black;
    text-decoration: none;
    padding-right: 20px;
    cursor: pointer
}
#services:hover{
    color:rgb(240, 147, 25)
}

#studio{
    color:black;
    text-decoration: none;
    padding-right: 50px;
    cursor: pointer
}

#items{
    color:black;
    text-decoration: none;
    font-size: 75%;
    cursor: pointer
}
#studio:hover{
    color:rgb(43, 174, 226)
}

.Mobile_Nav_item{
    font-family: Cairo;
    height:100%;
    display:-webkit-flex;
    display:flex;
    margin-top: 20px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;

}
@media only screen and (min-width:551px) {
    .Mobile_Nav{
        display:none
    }
    
  }

@media only screen and (max-width:550PX){
    .NavLeft_container{
        display:none;
        width:0
    }
    .Mobile_Nav{
        position: absolute;
        right: 30px;
        
    }
    #logo{
        width:120px
    }

}
.MobilePopup{
    position:fixed;
    top:0;
    left:0;
    width: 100vw;
    height:100vh;
    background-color: rgba(0,0,0,0.2);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 2;
}

.inner-MobilePopup{
    margin-top: 50px;
    position:relative;
    padding: 30px;
    width:60vw;
    background-color: rgb(255, 255, 255);
    max-width: 600px;
    max-height: 35vh;
    
}


.close_icon{
    position: absolute;
    top: 20px;
    right:30px
}

#items{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center
}

#items:hover{
    background-color: rgb(238, 238, 238)
}
